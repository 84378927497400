import React from "react"
import { Link } from "gatsby"

import "./_hero.scss"

const Hero3 = () => (
  <section className="hero is-medium is-link">
    <div className="hero-body">
      <div className="container is-max-desktop px-6 py-4 has-text-centered">
        <Link to="/kontakt">
          <h2>
          ثق بنا. يرجى الإتصال بنا مباشرة وبصورة غير مُلزِمة.
          </h2>
        </Link>
      </div>
    </div>
  </section>
)
export default Hero3
