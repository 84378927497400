import React from "react"

import "./_hero.scss"

const Hero2 = () => (
  <section className="hero is-small">
    <div className="hero-body">
      <div className="container is-max-desktop px-6 py-4 has-text-centered">
        <h4 className="has-text-justified">
        بالنسبة لشركة كلافيكون تقع أنت في بؤرة الحدث. وبوصفنا شريكك الاستراتيجي فإننا ندعمك بشكل شخصي وسري، نصائحنا وتصرفاتنا تسعى إلى هدفٍ واحدٍ فقط ألا وهو: تأمينك الشامل والمستدام. جميع أعمالنا تُمثل دائماً الاحترافية .والالتزام وهما أهم الأسس في علاقاتنا مع عملائنا
        </h4>
      </div>
    </div>
  </section>
)
export default Hero2
