import React from "react"

import "./_hero.scss"

const Hero1 = () => (
    <section className="hero is-medium is-link">
      <div className="hero-body">
        <div className="container has-text-centered">
          <h2>الصفحة الرئيسية</h2>
        </div>
      </div>
    </section>
  )
export default Hero1
