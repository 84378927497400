import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import "./_leistungen.scss"

const Leistungen = () => (
  <section className="section leistungen">
    <div className="container">
      <div className="columns">
        <div className="column">
          <div className="card">
            <div class="card-content">
              <div className="content">
                <figure
                  className="card-image has-text-centered"
                  style={{
                    marginBottom: `0px`,
                  }}
                >
                  <StaticImage
                    src="../../images/icon_clavicon.png"
                    width={80}
                    quality={65}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Clavicon Icon"
                    className="image is-inline-block"
                    placeholder="tracedSVG"
                  />
                </figure>
                <h4 className="has-text-centered mt-3">أمن الشركات</h4>
                كن مُستعدًا – حماية شاملة وفردية للشركات والموظفين والممتلكات
              </div>
            </div>
            <footer class="card-footer">
              <Link to="/corporate-security" className="card-footer-item has-text-white">
              أكثر
              </Link>
            </footer>
          </div>
        </div>

        <div className="column">
          <div className="card">
            <div class="card-content">
              <div className="content">
                <figure
                  className="card-image has-text-centered"
                  style={{
                    marginBottom: `0px`,
                  }}
                >
                  <StaticImage
                    src="../../images/icon_clavicon.png"
                    width={80}
                    quality={65}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Clavicon Icon"
                    className="image is-inline-block"
                    placeholder="tracedSVG"
                  />
                </figure>
                <h4 className="has-text-centered mt-3">الأمن الخاص</h4>
                احترافية والسرية – حلول أمنية مُصممة لتناسب احتياجات الأفراد وعائلاتهم
              </div>
            </div>
            <footer class="card-footer">
              <Link to="/private-security" className="card-footer-item has-text-white">
              أكثر
              </Link>
            </footer>
          </div>
        </div>

        <div className="column">
          <div className="card">
            <div class="card-content">
              <div className="content">
                <figure
                  className="card-image has-text-centered"
                  style={{
                    marginBottom: `0px`,
                  }}
                >
                  <StaticImage
                    src="../../images/icon_clavicon.png"
                    width={80}
                    quality={65}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Clavicon Icon"
                    className="image is-inline-block"
                    placeholder="tracedSVG"
                  />
                </figure>
                <h4 className="has-text-centered mt-3">الأمن المعلوماتي</h4>
                لا تفوت فرصة الاتصال بنا – حماية مخصصة خصيصا لتقي من الأخطار والتهديدات المتوقعة من الفضاء الالكتروني.
              </div>
            </div>
            <footer class="card-footer">
              <Link to="/information-security" className="card-footer-item has-text-white">
              أكثر
              </Link>
            </footer>
          </div>
        </div>

        <div className="column">
          <div className="card">
            <div class="card-content">
              <div className="content">
                <figure
                  className="card-image has-text-centered"
                  style={{
                    marginBottom: `0px`,
                  }}
                >
                  <StaticImage
                    src="../../images/icon_clavicon.png"
                    width={80}
                    quality={65}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Clavicon Icon"
                    className="image is-inline-block"
                    placeholder="tracedSVG"
                  />
                </figure>
                <h4 className="has-text-centered mt-3">البرامج التدريبية</h4>
                الوقاية من خلال المعرفة – برامج تدريبية وتدابير توعوية كجزء من مفهوم المنظومة الأمنية الشاملة
              </div>
            </div>
            <footer class="card-footer">
              <Link to="/training" className="card-footer-item has-text-white">
              أكثر
              </Link>
            </footer>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Leistungen
