import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero1 from "../components/hero/hero1"
import Hero2 from "../components/hero/hero2"
import Hero3 from "../components/hero/hero3"
import Services from "../components/services"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Hero1 />
    <Hero2 />
    <Services />
    <Hero3 />
  </Layout>
)

export default IndexPage
